<template>
 <div class="min-h-full ">
  <main class="pt-8 pb-16">
   <div class="mx-auto sm:px-6 lg:px-8 ">
    <div class="px-4 sm:px-0 ">
     <div class="">
      <div class="mt-3 sm:mt-0 sm:ml-0 flex-1">
       <h2 class="capitalize text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
        {{ $t("Quizzes") }}
       </h2>
      </div>
     </div>
     <!-- Tabs -->
     <div class="sm:hidden ">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select
       id="tabs"
       name="tabs"
       class="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
      >
       <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
      </select>
     </div>
     <div class="hidden sm:block">
      <div class="border-b border-gray-200">
       <nav class="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
        <a
         v-for="tab in tabs"
         :key="tab.name"
         :href="tab.href"
         :class="[
          tab.current
           ? 'border-gray-500 text-gray-600'
           : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
          'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
         ]"
         @click="setActiveTab(tab.name)"
        >
         {{ tab.name }}
         <span
          v-if="tab.count"
          :class="[
           tab.current ? 'bg-gray-300 text-gray-900' : 'bg-gray-200 text-gray-500',
           'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block',
          ]"
          >{{ tab.count }}</span
         >
        </a>
       </nav>
      </div>
     </div>
    </div>

    <!-- Stacked list -->
    <ul
     v-if="activeTab === 'Active'"
     role="list"
     class="shadow-md rounded-md px-4 mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0 bg-white"
    >
     <li v-for="quiz in quizzes" :key="quiz.id">
      <a
       @click="
        goToQuiz(
         quiz.id,
         quiz.subject,
         `Start quiz ${quiz.subject}`,
         'You are about to start the quiz. Are you ready to continue?',
         'Continue',
         'Go back'
        )
       "
       class="group block cursor-pointer"
      >
       <div class="flex items-center py-5 px-4 sm:py-6 sm:px-0">
        <div class="flex min-w-0 flex-1 items-center">
         <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div>
           <p class="truncate text-sm font-medium text-purple-600">
            {{ quiz.subject }}
           </p>
           <!-- <p class="mt-2 flex items-center text-sm text-gray-500">
                        <span class="truncate">{{ quiz.email }}</span>
                      </p> -->
          </div>
          <div class="hidden md:block">
           <div>
            <p class="text-sm text-gray-900">
             {{ $t("createdAt") }}
             {{ " " }}
             <!-- <time :datetime="quiz.appliedDatetime">{{
                            quiz.applied
                          }}</time> -->
             {{ this.formatDate(quiz.created_at) }}
            </p>
            <p class="mt-2 flex items-center text-sm text-gray-500">
             <CheckCircleIcon
              v-if="quiz.active"
              class="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
              aria-hidden="true"
             />
             <XCircleIcon
              v-else
              class="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
              aria-hidden="true"
             />
             {{ quiz.active ? $t("active") : $t("inactive") }}
            </p>
           </div>
          </div>
         </div>
        </div>
        <div>
         <ChevronRightIcon
          class="h-5 w-5 text-gray-400 group-hover:text-gray-700"
          aria-hidden="true"
         />
        </div>
       </div>
      </a>
     </li>
    </ul>
    <ul
     v-else
     role="list"
     class="shadow-md rounded-md px-4 mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0 bg-white"
    >
     <li v-for="quiz in quizEvaluations" :key="quiz.uuid">
      <a
       @click="this.$router.push(`/training/quiz-results/${quiz.uuid}`)"
       class="group block cursor-pointer"
      >
       <div class="flex items-center py-5 px-4 sm:py-6 sm:px-0">
        <div class="flex min-w-0 flex-1 items-center">
         <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div>
           <p class="truncate text-sm font-medium text-purple-600">
            {{
             getQuizById(quiz.quiz_id) && getQuizById(quiz.quiz_id).subject
              ? getQuizById(quiz.quiz_id).subject
              : ""
            }}
           </p>
           <p
            :class="[
             quiz.passed ? 'text-green-500' : 'text-red-500',
             'mt-2 flex items-center text-sm font-bold gap-1',
            ]"
           >
            <font-awesome-icon v-if="quiz.passed" icon="star" />
            <font-awesome-icon v-else icon="star-half-stroke" />
            <span class="items-center">{{ (quiz.score_percent * 10).toFixed(2) }}</span>
           </p>
          </div>
          <div class="hidden md:block">
           <div>
            <p v-if="quiz.completed_at" class="text-sm text-gray-900">
             {{ $t("completedAt") }}
             {{ " " }}
             <!-- <time :datetime="quiz.appliedDatetime">{{
                            quiz.applied
                          }}</time> -->
             {{ this.formatDate(quiz.completed_at) }}
            </p>
            <p v-else class="text-sm text-gray-900">
             {{ $t("quizNotCompleted") }}
            </p>
            <p class="mt-2 flex items-center text-sm text-gray-500">
             <CheckCircleIcon
              v-if="quiz.passed"
              class="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
              aria-hidden="true"
             />
             <XCircleIcon
              v-else
              class="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
              aria-hidden="true"
             />
             {{ quiz.passed ? $t("aproved") : $t("reproved") }}
            </p>
           </div>
          </div>
         </div>
        </div>
        <div>
         <ChevronRightIcon
          class="h-5 w-5 text-gray-400 group-hover:text-gray-700"
          aria-hidden="true"
         />
        </div>
       </div>
      </a>
     </li>
    </ul>
   </div>
  </main>
 </div>
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";
import axios from "axios";
import {
 CalendarIcon,
 CheckCircleIcon,
 XCircleIcon,
 CheckIcon,
 ChevronDownIcon,
 ChevronRightIcon,
 EnvelopeIcon,
} from "@heroicons/vue/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/vue/outline";

const tabs = [
 { name: "Active", href: "#", count: 0, current: true },
 { name: "Results", href: "#", count: 0, current: false },
];
const candidates = [
 {
  name: "Emily Selman",
  email: "emily.selman@example.com",
  imageUrl:
   "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  applied: "January 7, 2020",
  appliedDatetime: "2020-07-01T15:34:56",
  status: "Completed phone screening",
 },
 // More candidates...
];

export default {
 data() {
  return {
   tabs,
   candidates,
   quizzes: [],
   quizEvaluations: [],
  };
 },
 components: {
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  EnvelopeIcon,
  XCircleIcon,
  AskConfirmationDialog,
 },
 methods: {
  getQuizById(id) {
   console.log(
    "file: TrainingQuizzes.vue ~ line 257 ~ returnthis.quizzes.filter ~ this.quizzes",
    this.quizzes
   );
   return this.quizzes.filter((quiz) => {
    return quiz.id == id;
   })[0];
  },
  async goToQuiz(id, subject, title, confirmationMessage, confirmButton, goBack) {
   let href = `/training/quiz/${id}/${subject}`;
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "",
   });
   if (r) {
    let url = "v1/training/quiz-evaluations";
    let data = {
     quiz_id: id,
    };
    axios.post(`${this.$cookie.getCookie("API")}/api/${url}`, data).then((res) => {
     console.log("file: TrainingQuizzes.vue ~ line 216 ~ axios.post ~ res", res.data);
     this.$router.push(href + "/" + res.data.uuid);
    });
   }
  },
  async getQuizzes() {
   this.$emit("setLoading", true);
   const url = "api/v1/training/quizzes";
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/${url}`);
    console.log("file: TrainingQuizzes.vue ~ line 196 ~ getQuizzes ~ res", res.data);
    this.setQuizzesCount(res.data);
    this.quizzes = res.data;
    this.getEvaluations();
    this.$emit("setLoading", false);
    return res.data;
   } catch (error) {
    this.errorHandling(error);
    this.$emit("setLoading", false);
   }
  },
  async getEvaluations() {
   const url = "api/v1/training/quiz-evaluations";
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/${url}`);
    this.quizEvaluations = res.data;
    console.log("file: TrainingQuizzes.vue ~ line 254 ~ getEvaluations ~ res.data", res.data);
    this.tabs.map((tab) => {
     tab.name === "Results" ? (tab.count = res.data.length) : "";
    });
    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
   this.$emit("setLoading", false);
  },
  async setQuizzesCount(data) {
   let activeQuizzes = [];
   if (data && data.length > 0) {
    activeQuizzes = data.filter((res) => {
     return res.active;
    });
   }
   this.tabs.map((res) => {
    res.name === "Active" ? (res.count = activeQuizzes.length) : "";
   });
  },
  setActiveTab(name) {
   this.tabs.map((item) => {
    item.name === name ? (item.current = true) : (item.current = false);
   });
   this.activeTab = name;
  },
 },
 mounted() {
  this.getQuizzes();
 },
 computed: {
  activeTab() {
   return this.tabs.filter((item) => {
    return item.current;
   })[0].name;
  },
 },
};
</script>
